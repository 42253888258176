import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { BasicCards } from "./components/DataCards";
import DataTable from "./components/DataTable";
import StockDetailsPopup from "./components/StockDetailsPopup";
import SendEmailPopup from "./components/SendEmailPopup";

function BasicDashboard() {
  const [loading, setLoading] = useState(false); // State for loading
  const [table, setTable] = useState([]); // State for loading
  const [selectedCurrency, setSelectedCurrency] = useState("1"); // State for loading
  const [selectedCountry, setSelectedCountry] = useState(""); // State for loading
  const [assetAnalysisData, setAssetAnalysisData] = useState({});
  const [openStockAnalysisModal, setOpenStockAnalysisModal] = useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [selectedSymbolId, setSelectedSymbolId] = useState(null);
  const [selectedSymbolName, setSelectedSymbolName] = useState(null);
  const [selectedSymbolCount, setSelectedSymbolCount] = useState(null);
  const [message, setMessage] = useState("");
  const [stats, setStats] = useState({
    worth: 0,
    symbols: 0,
    portfolios: 0,
    portfolioSymbols: 0,
  });
  const [currency, setCurrency] = useState({
    id: 1,
    name: "NGN",
    rate: 1650.6,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (country_id, currency_id) => {
    try {
      setLoading(true);
      const response = await axios.get("/portfolio/get-general-data/", {
        params: {
          country_id: country_id,
          currency_id: currency_id,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setStats({
          worth: data.totalWorthSum,
          symbols: data.uniqueSymbols,
          portfolios: data.uniquePortfolios,
          portfolioSymbols: data.uniquePortfolioSymbolPairs,
        });
        setTable(data.generalData);
        setCurrency(data.conversionData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const onCurrencyChange = (value) => {
    if (value) {
      setSelectedCurrency(value);
      fetchData(selectedCountry, value);
    }
  };

  const onCountryChange = (value) => {
    setSelectedCountry(value);
    fetchData(value, selectedCurrency);
  };

  const fetchSymbolData = async (selectedSymbolId) => {
    if (!selectedSymbolId) {
      toast.warn("Please select valid asset");
      return;
    }
    try {
      const response = await axios.get("/portfolio/get-symbol-analysis/", {
        params: {
          symbol_id: selectedSymbolId,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setAssetAnalysisData(data);
        setOpenStockAnalysisModal(true);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
    }
  };

  const emailHolders = (id, name, count) => {
    setMessage("");
    setSelectedSymbolId(id);
    setSelectedSymbolName(name);
    setSelectedSymbolCount(count);
    setEmailPopupOpen(true);
  };

  const handleSendMessage = async () => {
    if (!message.trim()) {
      return toast.warn("Enter a message.");
    }
    try {
      setLoading(true);
      const response = await axios.post("/portfolio/send-email-asset/", {
        symbol_id: selectedSymbolId,
        message: message,
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        toast.success(`Email sent to ${selectedSymbolCount} respondent(s)`);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setEmailPopupOpen(false);
      setMessage("");
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}

      {/* filter */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-body">
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="currency">Currency</InputLabel>
              <Select
                labelId="currency"
                value={selectedCurrency || ""} // State to manage selected value
                onChange={(e) => onCurrencyChange(e.target.value)} // Handler to update the state
                label="currency"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Currency</MenuItem>
                <MenuItem value="1">Naira</MenuItem>
                <MenuItem value="2">Dollar</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="country">Country</InputLabel>
              <Select
                labelId="country"
                value={selectedCountry || ""} // State to manage selected value
                onChange={(e) => onCountryChange(e.target.value)} // Handler to update the state
                label="country"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Country</MenuItem>
                <MenuItem value="1">Nigeria</MenuItem>
                <MenuItem value="2">USA</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <BasicCards data={stats} currency={currency} />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <DataTable
                  data={table}
                  currency={currency}
                  fetchSymbolData={fetchSymbolData}
                  emailHolders={emailHolders}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* Stock Analysis Component */}
      <StockDetailsPopup
        open={openStockAnalysisModal}
        onClose={() => setOpenStockAnalysisModal(false)}
        assetAnalysisData={assetAnalysisData}
      />

      {/* Email Popup */}
      <SendEmailPopup
        open={emailPopupOpen}
        onClose={() => setEmailPopupOpen(false)}
        selectedSymbolName={selectedSymbolName}
        selectedSymbolCount={selectedSymbolCount}
        handleSendMessage={handleSendMessage}
        message={message}
        setMessage={setMessage}
      />

      <ToastContainer />
    </div>
  );
}

export default BasicDashboard;
