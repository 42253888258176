import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faNairaSign } from "@fortawesome/free-solid-svg-icons";

// Define head cells. Only Name, Industry, Worth, Shares, and Occurrences are sortable.
const headCells = [
  { id: "icon", label: "Icon", sortable: false },
  { id: "name", label: "Name", sortable: true },
  { id: "industry", label: "Industry", sortable: true },
  { id: "total_worth", label: "Worth", sortable: true },
  { id: "total_shares", label: "Shares", sortable: true },
  { id: "count", label: "Occurrences", sortable: true },
  { id: "action", label: "Action", sortable: false },
];

// --- Sorting functions ---
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const comp = comparator(a[0], b[0]);
    if (comp !== 0) return comp;
    return a[1] - b[1];
  });
  return stabilizedArray.map((el) => el[0]);
}

// --- Main component ---
const DataTable = ({ data, currency, fetchSymbolData, emailHolders }) => {
  const icons = { NGN: faNairaSign, USD: faDollarSign };
  // Sorting & Pagination state
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("total_worth");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Action menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedSymbolId, setSelectedSymbolId] = useState(null);
  const [selectedSymbolName, setSelectedSymbolName] = useState(null);
  const [selectedSymbolCount, setSelectedSymbolCount] = useState(null);

  // Handle sorting requests
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Action menu handlers
  const handleMenuOpen = (event, row) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedSymbolId(row.symbol_id);
    setSelectedSymbolName(row.symbol);
    setSelectedSymbolCount(row.count);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedSymbolId(null);
  };

  const handleAnalysis = () => {
    handleMenuClose();
    fetchSymbolData(selectedSymbolId);
  };

  const handleView = () => {
    handleMenuClose();
    window.open(`https://assessworth.com/stock/${selectedSymbolId}`, "_blank");
  };

  const sendEmail = () => {
    handleMenuClose();
    emailHolders(selectedSymbolId, selectedSymbolName, selectedSymbolCount);
  };

  // Apply sorting and pagination to the data
  const sortedData = stableSort(data, getComparator(order, orderBy));
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.id === "action" ? "center" : "left"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData.map((row) => (
              <TableRow hover key={row.symbol_id}>
                {/* Icon */}
                <TableCell>
                  <img
                    src={row.icon}
                    alt={row.symbol}
                    style={{ width: 40, height: 40, objectFit: "contain" }}
                  />
                </TableCell>

                {/* Name: Company name with symbol underneath */}
                <TableCell>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {row.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {row.symbol}
                    </Typography>
                  </Box>
                </TableCell>

                {/* Industry */}
                <TableCell>{row.industry}</TableCell>

                {/* Worth */}
                <TableCell>
                  <span
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <FontAwesomeIcon icon={icons[currency.name]} />
                    <span style={{ marginLeft: 4 }}>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                      }).format(row.total_worth)}
                    </span>
                  </span>
                </TableCell>

                {/* Shares */}
                <TableCell>{row.total_shares}</TableCell>

                {/* Occurrences */}
                <TableCell>{row.count}</TableCell>

                {/* Action Menu */}
                <TableCell align="center">
                  <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {paginatedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Table Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Action Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAnalysis}>View Analysis</MenuItem>
        <MenuItem onClick={sendEmail}>Email Holders</MenuItem>
        <MenuItem onClick={handleView}>View on Assessworth</MenuItem>
      </Menu>
    </Paper>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      symbol_id: PropTypes.number.isRequired,
      symbol: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      industry: PropTypes.string.isRequired,
      total_worth: PropTypes.number.isRequired,
      total_shares: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DataTable;
