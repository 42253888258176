import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
//import Loader from "../../components/Loader/Loader"; // Import your loader component
import BasicDashboard from "./basic";

function Portfolio() {
  //const [loading, setLoading] = useState(false); // State for loading
  const [activeSection, setActiveSection] = useState(null); // Track which section is open

  // Toggle section visibility
  const toggleSection = (section) => {
    setActiveSection((prevSection) =>
      prevSection === section ? null : section
    );
  };

  return (
    <div>
      {/* {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )} */}

      {/* User Retention Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom: activeSection === "basic" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("basic")}
        >
          <strong>Basic Dashboard</strong>
        </div>
        {activeSection === "basic" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <BasicDashboard />
          </div>
        )}
      </div>

      {/* Another Section */}
      {/* <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "another" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("another")}
        >
          <strong>Another</strong>
        </div>
        {activeSection === "another" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <BasicDashboard />
          </div>
        )}
      </div> */}

      {/* Another Section */}
      {/* <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "another" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("another")}
        >
          <strong>Another</strong>
        </div>
        {activeSection === "another" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <BasicDashboard />
          </div>
        )}
      </div> */}

      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
}

export default Portfolio;
